@import 'scss/variables/index';

.pd-products {
  background: $primary-background-color;
  min-height: 100vh;
  padding-bottom: $spacing-xl;

  img.product {
    border-radius: $spacing-xs;
    margin: $spacing-lg 0;
    width: 100%;
  }

  img.banner {
    margin: 0;
    margin-left: -$spacing-md;
    width: calc(100% + 2 *#{$spacing-md});
  }

  &-divider {
    border-right: 1px solid darkgray;
    height: 360px;
    width: 1px;
  }

  &-header {
    color: $wilco-primary;
    font-size: 60px;
  }

  &-title {
    color: $wilco-primary;
    font-size: 24px;
    font-weight: bold;
  }

  &-description {
    color: $wilco-primary;

    .specs {
      padding: 5px 15px;
    }
  }

  &-extras {
    .flex-container {
      display: flex;
    }
  }

  .colored-bag {
    max-width: 600px;
  }

  .color-option {
    cursor: pointer;
    border-radius: 50%;
    border: 4px solid #d0e0e7;
    box-shadow: 0px 0px 0px 2px $pd-color-gray;
    display: inline-block;
    margin: $spacing-sm;
    height: 24px;
    width: 24px;
  }

  .ant-divider-vertical {
    background: $pd-color-gray;
  }

  .ant-carousel {
    .slick-slide {
      background: transparent;
      line-height: 400px;
      overflow: hidden;
      text-align: center;
    }

    .slick-dots {
      li {
        button {
          background: $secondary-color;
        }

        &.slick-active {
          button {
            background: $primary-color;
          }
        }
      }
    }
  }
}
