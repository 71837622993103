.pd-padding {
  &-top {
    &-2xs {
      padding-top: $spacing-2xs !important;
    }
    &-xs {
      padding-top: $spacing-xs !important;
    }
    &-sm {
      padding-top: $spacing-sm !important;
    }
    &-md {
      padding-top: $spacing-md !important;
    }
    &-lg {
      padding-top: $spacing-lg !important;
    }
    &-xl {
      padding-top: $spacing-xl !important;
    }
    &-2xl {
      padding-top: $spacing-2xl !important;
    }
    &-3xl {
      padding-top: $spacing-3xl !important;
    }
  }
  &-right {
    &-2xs {
      padding-right: $spacing-2xs !important;
    }
    &-xs {
      padding-right: $spacing-xs !important;
    }
    &-sm {
      padding-right: $spacing-sm !important;
    }
    &-md {
      padding-right: $spacing-md !important;
    }
    &-lg {
      padding-right: $spacing-lg !important;
    }
    &-xl {
      padding-right: $spacing-xl !important;
    }
    &-2xl {
      padding-right: $spacing-2xl !important;
    }
    &-3xl {
      padding-right: $spacing-3xl !important;
    }
  }
  &-bottom {
    &-2xs {
      padding-bottom: $spacing-2xs !important;
    }
    &-xs {
      padding-bottom: $spacing-xs !important;
    }
    &-sm {
      padding-bottom: $spacing-sm !important;
    }
    &-md {
      padding-bottom: $spacing-md !important;
    }
    &-lg {
      padding-bottom: $spacing-lg !important;
    }
    &-xl {
      padding-bottom: $spacing-xl !important;
    }
    &-2xl {
      padding-bottom: $spacing-2xl !important;
    }
    &-3xl {
      padding-bottom: $spacing-3xl !important;
    }
  }
  &-left {
    &-2xs {
      padding-left: $spacing-2xs !important;
    }
    &-xs {
      padding-left: $spacing-xs !important;
    }
    &-sm {
      padding-left: $spacing-sm !important;
    }
    &-md {
      padding-left: $spacing-md !important;
    }
    &-lg {
      padding-left: $spacing-lg !important;
    }
    &-xl {
      padding-left: $spacing-xl !important;
    }
    &-2xl {
      padding-left: $spacing-2xl !important;
    }
    &-3xl {
      padding-left: $spacing-3xl !important;
    }
  }
}
