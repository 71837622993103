$pd-color-black: #000;
$pd-color-white: #ffffff;
$pd-color-light-gray: #eeeeee;
$pd-color-gray: #cccccc;
$pd-color-dark-gray: #777777;
$pd-color-darker-gray: #444444;

// customer specific
$wilco-primary: #417505;
$wilco-secondary: #cfc83c;

// color theme
$primary-background-color: linear-gradient(#effbfe, #d0e0e7);
$primary-color: $wilco-primary;
$secondary-color: $wilco-secondary;
