@import 'scss/variables/index.scss';

.pd-header {
  padding: $spacing-lg 0;
  width: 100%;
  z-index: 1;

  .ant-btn-group {
    button:hover {
      border-color: $wilco-primary;
      color: $wilco-primary;
    }
  }

  .hamburger-menu {
    color: $primary-color;
    cursor: pointer;
    text-align: right;

    i {
      font-size: $pd-font-size-md;
    }
  }

  &-logo {
    width: 100%;
    max-width: 160px;
  }

  &-menu {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    &-item {
      color: $primary-color;
      padding: 0 $spacing-md;
      white-space: nowrap;

      &:hover {
        transition: color 0.2s;
        color: $pd-color-gray;
      }
    }
  }

  &-menu--mobile {
    background-color: rgba(255, 255, 255, 0.95);
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0%;
    position: fixed;
    top: 0%;
    width: 100%;
    z-index: 1;

    .ant-btn-group {
      padding: $spacing-md 0;
      padding-left: 32px;
    }

    .close-btn {
      cursor: pointer;
      position: fixed;
      z-index: 9;
    }

    .header {
      color: $primary-color;
      font-size: $pd-font-size-md;
      padding: $spacing-md $spacing-md;
    }

    .item {
      color: $primary-color;
      display: flex;
      flex-direction: column;
      font-size: $pd-font-size-sm;
      margin: 0 $spacing-lg;
      padding: $spacing-md 0;
      white-space: nowrap;

      &:not(:last-child) {
        border-bottom: solid 1px $primary-color;
      }
    }
  }
}
