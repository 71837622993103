@import 'scss/variables/index.scss';

.pd-footer {
  background: $primary-color;
  color: $pd-color-white;
  padding: $spacing-lg;
  text-align: center;

  &-sub {
    color: $pd-color-white;
    font-size: 12px;

    .link {
      color: $pd-color-white;
      font-weight: 600;
      font-size: 11px;
    }
  }
}
