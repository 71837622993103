@import 'scss/variables/index';

.pd-about {
  background: $primary-background-color;
  padding-bottom: $spacing-2xl;

  img {
    margin-left: -16px;
    width: calc(100% + 32px);
  }

  h3 {
    color: $primary-color;
  }

  &-title {
    color: $primary-color;
    font-size: 60px;
    margin-bottom: $spacing-lg;
  }

  &-subtitle {
    color: $primary-color;
    font-size: $pd-font-size-lg;
    margin-bottom: $spacing-md;
  }

  &-content {
    color: $primary-color;
    margin-bottom: $spacing-lg;

    &__list {
      padding: 0px $spacing-md;
    }
  }

  .ant-carousel .slick-slide {
    background: $primary-color;
    height: 320px;
    line-height: 320px;
    overflow: hidden;
    text-align: center;
  }

  .ant-carousel {
    .slick-slide {
      img {
        display: inline-block;
      }
    }
  }
}
