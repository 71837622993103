@import 'scss/variables/index';

.pd-introduction {
  background: $primary-background-color;
  color: $primary-color;
  padding-bottom: $spacing-xl;
  min-height: 100vh;

  img {
    margin-left: -$spacing-md;
    width: calc(100% + 2 *#{$spacing-md});
  }

  h1,
  h3 {
    color: $primary-color;
  }

  h1 {
    font-size: 70px;
  }

  h3 {
    font-size: 24px;
    padding-top: $spacing-2xl;
  }

  button {
    @include button-xl;

    background-color: $primary-color;
    border-radius: 25px;
    color: $pd-color-white;
    text-transform: uppercase;

    &:hover,
    &:focus {
      border: 1px solid $primary-color;
      background-color: $primary-color;
      color: $pd-color-white;
    }
  }
}

.pd-product {
  &-title {
    color: $primary-color;
  }

  &-subtitle {
    color: $secondary-color;
  }

  span {
    color: $primary-color;
  }
}

.pd-quality {
  padding: $spacing-2xl 0;

  h1,
  span {
    color: $primary-color;
  }

  &-item {
    display: flex;
    margin-bottom: $spacing-lg;
  }
}

.pd-traction {
  background: $pd-color-light-gray;
  padding: $spacing-xl 0;
  text-align: center;

  &-title h2 {
    font-weight: bold;
    text-transform: uppercase;
  }

  img {
    max-width: 240px;
    padding: $spacing-lg;
  }
}

.pd-showcase {
  padding-top: $spacing-2xl;

  img {
    width: 100%;
  }

  h1 {
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }
}

.pd-product {
  padding: $spacing-2xl 0;

  img {
    border-radius: $spacing-sm;
    width: 100%;
  }

  &-title {
    font-size: 70px;
    font-weight: bold;
  }

  &-subtitle {
    font-size: 24px;
  }

  button {
    @include button-xl;

    border-radius: 25px;
    text-transform: uppercase;

    &:hover,
    &:focus {
      border: 1px solid $primary-color;
      background-color: $pd-color-white;
      color: $primary-color;
    }
  }
}
