@import 'scss/variables/index';

.pd-contact {
  background: $primary-background-color;
  color: $primary-color;
  min-height: 100vh;
  padding-bottom: $spacing-2xl;

  h1 {
    color: $primary-color;
  }

  &-title {
    color: $primary-color;
    font-size: 60px;
    margin-bottom: $spacing-lg;
  }

  &-map {
    border-radius: 4px;
    box-shadow: 0 2px 4px $pd-color-gray;
    width: 100%;
  }

  .ant-carousel .slick-slide {
    text-align: center;
    height: 320px;
    line-height: 320px;
    background: $primary-color;
    overflow: hidden;
  }

  .ant-carousel .slick-slide h3 {
    color: #fff;
  }

  button {
    @include button-xl;

    background-color: $primary-color;
    border-radius: 25px;
    color: $pd-color-white;
    text-transform: uppercase;

    &:hover,
    &:focus {
      border: 1px solid $primary-color;
      background-color: $primary-color;
      color: $pd-color-white;
    }
  }
}
