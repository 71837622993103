@import 'scss/utils/index';
@import 'scss/variables/index';

.ant-row,
.ant-row-flex {
  padding-left: $spacing-md !important;
  padding-right: $spacing-md !important;
}

body {
  font-family: 'Brandon', sans-serif;
  font-size: 16px;
}

form {
  input,
  textarea {
    border: 1px solid $pd-color-gray;
    border-radius: 4px;
    padding: $spacing-sm $spacing-md;
    margin-bottom: $spacing-md;
    width: 100%;
  }

  textarea {
    resize: none;
    min-height: 200px;
    width: 100%;
  }
}
