@import 'scss/variables/index';

.pd-faq {
  background: $primary-background-color;
  color: $primary-color;
  padding-bottom: $spacing-2xl;
  min-height: 100vh;

  &-title {
    color: $primary-color;
    font-size: 60px;
    margin-bottom: $spacing-lg;
  }

  .ant-divider-vertical {
    background: $pd-color-gray;
  }

  &-wrapper {
    margin-bottom: $spacing-lg;

    &-question {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: $spacing-sm;
    }
  }

  &-extras {
    > div {
      margin-bottom: $spacing-lg;
    }

    &-title {
      font-weight: 500;
      font-size: 20px;
    }
  }
}
